<template>
  <div>
    <div>
      <button class="orange-btns hidden border-2 text-center lg:inline-block border-primary rounded-full px-12 py-2 mt-4 font-semibold transition-all ease-in-out duration-500" @click="selecttab(1);hidebod();height();">
        <slot name="btn-text"/>
      </button>
      <button class=" border-2 bg-primary text-white text-center lg:hidden inline-block border-primary rounded-full px-12 py-2 mt-4 font-semibold transition-all ease-in-out duration-500" @click="selecttab(1);hidebod();height();">
        <slot name="btn-text"/>
      </button>
      <div v-if="selected===1" class="fixed opacity-100 z-50 w-screen h-screen overflow-y-scroll lg:flex lg:items-center top-0 lg:-top-16 lg:top-0 left-0 right-0 bottom-0">
        <div class="fixed z-50 w-screen h-screen" @click="selecttab(0);hidebod();height();" style="filter: blur(2px);backdrop-filter: blur(10px);"></div>
        <div class="mx-auto text-right relative z-50 bg-white opacity-100 shadow-box-light lg:rounded-xl mt-8 lg:mt-0 modal-l px-6 lg:px-12 py-8 h-9/12 w--full lg:w-800 lg:h-450 overflow-x-hidden">
          <button type="button" name="button" class="inline-flex lg:mt-2 mb-4 ml-auto gap-x-4 items-center font-light text-primary group" @click="selecttab(0);hidebod();height();">
            <div class="text-primary group-hover:text-primary group-hover:bg-white text-xl transition-all ease-in-out duration-300 p-1 z-10 border-white border">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </button>
          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: 0,
    }
  },
  methods: {
    selecttab(tab){
      this.selected = tab
    },
    hidebod () {
      this.isOpen = true
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    height () {
      this.isOpen = true
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  },
}
</script>
