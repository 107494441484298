import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import $ from "jquery";
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppNavBar from './components/AppNavBar'
import ContactForm from './components/ContactForm'
import Modal from './components/Modal'
import Donate from './components/Donate'
import Donations from './components/Donations'
Vue.component('Donations', Donations)
Vue.component('Donate', Donate)
Vue.component('Modal', Modal)
Vue.component('AppNavBar', AppNavBar)
Vue.component('ContactForm', ContactForm)


const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
$(document).ready(function(){
  $('#nav-icon4').click(function(){
    $(this).toggleClass('open');
  });
});




