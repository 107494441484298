<template>
  <div>
    <div id="nav" class="relative z-30">
      <div class="w-screen overflow-hidden relative z-10 px-8">
        <div class="container relative z-20 pt-8 relative flex justify-between items-center">
          <div class="relative mb-0">
            <slot name="brand"/>
          </div>
          <div class="h-8 flex gap-x-8">
            <div>
              <a href="/contact/" class="contat-header inline-block">Contact us</a>
            </div>
            <div>
              <a href="/donations/" class="donate-header">Donate</a>
<!--              <donate>-->
<!--                -->
<!--                <template slot="btn-text">-->
<!--                  <p></p>-->
<!--                </template>-->
<!--                <div class="border-primary rounded-60 py-12 border-2">-->
<!--                  <p class="font-medium text-primary text-28 mb-8 text-center">Bank Transfer Details</p>-->
<!--                  <div class="space-y-2">-->
<!--                    <p class="text-18 leading-28 text-center">Bank: Ulster Bank</p>-->
<!--                    <p class="text-18 leading-28 text-center">Type: Business Current</p>-->
<!--                    <p class="text-18 leading-28 text-center">Account: 20978268</p>-->
<!--                    <p class="text-18 leading-28 text-center">Sort Code: 98-00-60</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </donate>-->
            </div>
            <button name="main menu" aria-label="main menu" type="button" @click="addo();addy();addb();addc();addbc();"
                    class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container z-10 heighter">
      <div class="py-12 lg:py-24 flex justify-center">
        <div>
          <slot name="mainmenu"/>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isOpenLeft: false,
      services: false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo() {
      document.querySelector('.heighter').classList.toggle('heights')
    },
    addy() {
      document.querySelector('.content-pos').classList.toggle('toppos')
    },
    addb() {
      document.querySelector('.brand').classList.toggle('white-b')
    },
    addc() {
      document.querySelector('.contat-header').classList.toggle('white-c')
    },
    addbc() {
      document.querySelector('.donate-header').classList.toggle('white-bc')
    },
  }
}
</script>
